<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-05-07 14:57:21
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-12-01 16:09:37
-->
<template>
  <el-card class="box" v-loading="loading">
    <div class="avatar">
      <el-avatar :src="model.avatar_pic"></el-avatar>
    </div>
    <el-descriptions title="基本信息" border>
      <el-descriptions-item label="商户">{{
        model.business?.name
      }}</el-descriptions-item>
      <el-descriptions-item label="姓名">{{
        model.chs_name
      }}</el-descriptions-item>
      <el-descriptions-item label="手机">{{
        model.mobile_phone
      }}</el-descriptions-item>
      <el-descriptions-item label="账号">{{
        model.user_name
      }}</el-descriptions-item>
      <el-descriptions-item label="部门">{{
        model.dept_full_name
      }}</el-descriptions-item>
      <el-descriptions-item label="职位">{{
        model.job_name ?? ""
      }}</el-descriptions-item>
      <el-descriptions-item label="岗位角色">
        {{
          model.post_list ? model.post_list.map((o) => o.name).join("、") : ""
        }}
      </el-descriptions-item>
      <el-descriptions-item label="状态">
        <el-tag type="success" v-if="model.status == 0">正常</el-tag>
        <el-tag type="danger" v-if="model.status == 10">停用</el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="创建时间">{{
        model.create_at
      }}</el-descriptions-item>
    </el-descriptions>
  </el-card>
  <el-card class="box">
    <el-descriptions title="安全设置" :column="2">
      <el-descriptions-item label="密码">
        <el-button type="primary" link @click="visiblePasswordDialog = true"
          >修改</el-button
        >
      </el-descriptions-item>
    </el-descriptions>
  </el-card>

  <el-dialog
    title="修改密码"
    v-model="visiblePasswordDialog"
    width="600px"
    draggable
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="手机">
        {{ model.mobile_phone }}
        <el-button
          type="primary"
          size="small"
          link
          ref="vaptcha"
          style="margin-left: 20px"
          @click="onGetSmsCode"
          :disabled="btnSms.disabled"
          >{{ btnSms.text }}</el-button
        >
      </el-form-item>

      <el-form-item label="验证码" prop="SmsCode">
        <el-col :span="10" :offset="0">
          <el-input
            v-model="form.SmsCode"
            placeholder="请输入短信验证码"
          ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="密码" prop="Password">
        <el-col :span="16" :offset="0">
          <el-input
            v-model="form.Password"
            placeholder="请输入登录密码"
          ></el-input>
        </el-col>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary" :loading="saving" @click="onSubmitPwd"
        >确定</el-button
      >
    </template>
  </el-dialog>
</template>


<script>
import md5 from "js-md5";
import center_api from "@/http/center_api";
import common_api from "@/http/common_api";

export default {
  data() {
    return {
      loading: false,
      saving: false,
      visiblePasswordDialog: false,
      model: {},
      rules: {
        SmsCode: [{}],
      },
      form: {},
      btnSms: {
        text: "获取验证码",
        disabled: false,
      },
    };
  },
  created() {
    var _this = this;

    //参数
    common_api.get_config().then((res) => {
      window
        .vaptcha({
          vid: res.data.vaptcha_vid,
          mode: "invisible",
        })
        .then(function (VAPTCHAObj) {
          _this.vaptcha = VAPTCHAObj;
          // 渲染验证组件
          VAPTCHAObj.render();

          // 验证成功进行后续操作
          VAPTCHAObj.listen("pass", function () {
            _this.btnSms.disabled = true;
            var serverToken = VAPTCHAObj.getServerToken();
            _this.$http
              .post("common/sms/captcha", {
                mobile: _this.model.mobile_phone,
                type: 0,
                token: serverToken.token,
                server: serverToken.server,
                scene: 0,
              })
              .then((res) => {
                if (res.code == 0) {
                  _this.$message({
                    type: "success",
                    message: res.msg,
                  });
                  //发送成功，倒计时
                  _this.btnSms.disabled = true;
                  var sec = 60;
                  var t = setInterval(() => {
                    sec--;
                    if (sec <= 0) {
                      clearInterval(t);
                      _this.btnSms.disabled = false;
                      _this.btnSms.text = "获取验证码";
                      VAPTCHAObj.reset();
                    } else {
                      _this.btnSms.text = `${sec}s`;
                    }
                  }, 1000);
                } else {
                  VAPTCHAObj.reset();
                }
              });
          });

          VAPTCHAObj.listen("close", function () {
            console.log("验证弹窗关闭触发");
            // 验证弹窗关闭触发
            VAPTCHAObj.reset();
          });
        });
    });
    this.loadData();
  },
  mounted() {},
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      center_api
        .get_my()
        .then((res) => {
          if (res.code == 0) {
            this.model = res.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /**
     * 获取验证码
     */
    onGetSmsCode() {
      this.vaptcha.validate();
    },
    /**
     * 提交密码修改
     */
    onSubmitPwd() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          var data = Object.assign({}, this.form);
          data.Password = md5(this.form.Password + this.model.salt);
          this.$http
            .post("/seller/v1/center/changePwd", data)
            .then((res) => {
              this.saving = false;
              if (res.code == 0) {
                this.visiblePasswordDialog = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
              }
            })
            .cath(() => {
              this.saving = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.avatar {
  margin-bottom: 30px;
}

.descriptions-label {
  width: 100px;
}
</style>